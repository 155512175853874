html,
body {
  background: #f7f7f7;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  border-radius: 2px;
  background-color: #ebebeb;
}
::-webkit-scrollbar-track {
  background-color: #ebebeb;
}
::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: #b2b2b2;
}
